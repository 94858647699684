import { createContext, useContext, useState } from "react";

const UserContext = createContext({});

export const UserProvider = ({ children })=>{
    const [user, setUser] = useState();

    const setUserData = (args) =>{
        setUser(args);
    }
    return (
        <UserContext.Provider value={{ user, setUserData }}>
          {children}
        </UserContext.Provider>
      );
};

export const useUserContext = () => useContext(UserContext);